import React, {Component} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import * as PropTypes from "prop-types";
import SEO from "../components/seo";
import Img from "gatsby-image";
import {Col, Row} from "antd";

export default class AboutPage extends Component {
    render() {
        let {data} = this.props; // this prop will be injected by the GraphQL query below.
        const {markdownRemark, partners} = data; // data.markdownRemark holds our post data
        const {html} = markdownRemark.childMarkdownRemark;

        return (
            <Layout>
                <SEO title="О нас"/>

                <Row style={{marginBottom: "50px"}}>
                    <Col lg={12} md={24}>
                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{__html: html}}
                        />
                    </Col>
                </Row>

                <h3>ПАРТНЕРЫ БЮРО</h3>

                <Row gutter={[{md:24+16, lg:24+16+50}, 48]}>
                    {partners.nodes.map((node, idx) => {
                        let id = idx;
                        let fluid = node.image.childImageSharp.fluid;
                        let name = node.name;
                        let name_style = {marginTop: "5px"};
                        if(node.black) {
                            name_style["borderStyle"] = "solid";
                            name_style["paddingLeft"] = "3px";
                        }

                        return <Col lg={6} md={12} sm={24} style={{width:"100%"}} key={id}>
                            <Img fluid={fluid}
                                 // style={{ height: "200px"}}
                                 // objectFit="contain"
                            />
                            <div style={name_style}>{name}</div>
                        </Col>
                    })}
                </Row>
            </Layout>
        )
    }
}

AboutPage.propTypes = {data: PropTypes.any};


export const pageQuery = graphql`
    query {
        markdownRemark: file(
            sourceInstanceName: {eq: "about"}, 
            ext: {eq: ".md"}) 
        {
            childMarkdownRemark {
                html
            }
        }

        partners: allPartnersYaml {
            nodes {
                name
                black
                id
                image {
                    id
                    childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
